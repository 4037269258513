<template id="admin-orders">
  <form :action="deliveryAction" method="POST" target="_blank" ref="form">
    <input type="hidden" name="action" :value="action">
    <input type="hidden" name="x-auth-token" :value="$store.state.authToken">
    <section class="d-flex flex-column">
      <div class="d-flex justify-content-lg-between justify-content-center flex-column flex-md-row">
        <h1 class="text-center text-md-left">Orders</h1>
        <b-button-group>
          <b-button variant="info" @click="newOrder">
            <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">New Order</span>
          </b-button>
          <b-button v-b-modal.modal-2 variant="primary">
            <font-awesome-icon :icon="['far','eye']"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">Overview</span>
          </b-button>
          <b-button @click.prevent="loadOrders" variant="success">
            <font-awesome-icon :icon="['far','sync']"></font-awesome-icon>
            <span class="d-none d-md-inline ml-2">Refresh</span>
          </b-button>
          <b-dropdown right text="Print">
            <template #button-content>
              &#9113; Action
            </template>
            <b-dropdown-item-btn @click="submit('invoice')" name="action">
              <font-awesome-icon :icon="['far','print']"></font-awesome-icon>
              <span class="d-none d-md-inline ml-2">Print Invoices</span>
            </b-dropdown-item-btn>
            <b-dropdown-item-btn @click="submit('invoice-schedule')" name="action">
              <font-awesome-icon :icon="['far','calendar-check']"></font-awesome-icon>
              <span class="d-none d-md-inline ml-2">Print Invoices & Schedule</span>
            </b-dropdown-item-btn>
            <b-dropdown-item-btn @click="submit('overview')" name="action">
              <font-awesome-icon :icon="['far','eye']"></font-awesome-icon>
              <span class="d-none d-md-inline ml-2">Print Overview</span>
            </b-dropdown-item-btn>
            <b-dropdown-item-btn @click="submit('sticker')" name="action">
              <font-awesome-icon :icon="['far','print-search']"></font-awesome-icon>
              <span class="d-none d-md-inline ml-2">Print Address</span>
            </b-dropdown-item-btn>
            <b-dropdown-item-btn @click="submit('front-sticker')" name="action">
              <font-awesome-icon :icon="['far','shredder']"></font-awesome-icon>
              <span class="d-none d-md-inline ml-2">Print Front Sticker</span>
            </b-dropdown-item-btn>
            <b-dropdown-item-btn @click="submit('store-sticker')" name="action">
              <font-awesome-icon :icon="['far','paint-roller']"></font-awesome-icon>
              <span class="d-none d-md-inline ml-2">Print Store Sticker</span>
            </b-dropdown-item-btn>
            <b-dropdown-item-btn @click="submit('amazon')" name="action">
              <font-awesome-icon :icon="['far','truck-ramp']"></font-awesome-icon>
              <span class="d-none d-md-inline ml-2">Amazon Upload</span>
            </b-dropdown-item-btn>
            <b-dropdown-item-btn @click="submit('delhivery')" name="action">
              <font-awesome-icon :icon="['far','truck-couch']"></font-awesome-icon>
              <span class="d-none d-md-inline ml-2">Delhivery Upload</span>
            </b-dropdown-item-btn>
          </b-dropdown>
        </b-button-group>
      </div>
      <div class="d-flex justify-content-lg-between flex-column flex-md-row">

        <div class="mr-0 mr-md-2">
          <b-form-input v-model="orderNum"
                        placeholder="Order Number"
                        class="mr-0 mr-md-3 w-100 w-md-50 mt-3"></b-form-input>

        </div>
        <div class="mr-0 mr-md-2" style="min-width: 200px">
          <v-select v-model="orderStatuses"
                    @input="loadOrders"
                    class="mr-0 mr-md-3 w-100 w-md-50 mt-3"
                    :options="orderStatusList"
                    :reduce="p=>p.id"
                    label="name"
                    multiple></v-select>

        </div>

        <div class="mr-0 mr-md-2">
          <b-form-select v-model="paid"
                         @change="loadOrders"
                         class="mr-0 mr-md-3 w-100 w-md-50 mt-3">
            <option value="ALL">Paid & Unpaid</option>
            <option value="UNPAID">Unpaid</option>
          </b-form-select>

        </div>
        <div class="mr-0 mr-md-2">
          <b-form-select v-model="brand"
                         @change="loadOrders"
                         class="mr-0 mr-md-3 w-100 w-md-50 mt-3">
            <option value="ALL">All Brands</option>
            <option v-for="b of $store.state.brands" :value="b.id" :key="b.id">{{ b.name }}</option>
          </b-form-select>

        </div>
        <div class="mr-0 mr-md-2 mt-3">
          <div class="d-flex flex-column w-100 w-md-50">
            <b-form-select v-model="s.dateRange"
                           @change="loadOrders">
              <option value="SEVEN">Last 7 Days</option>
              <option value="THIRTY">Last 30 Days</option>
              <option value="SIXTY">Last 60 Days</option>
              <option value="NINETY">Last 90 Days</option>
              <option value="CUSTOM">Custom</option>
            </b-form-select>


            <b-form-datepicker v-model="s.fromDate"
                               v-if="s.dateRange === 'CUSTOM'"
                               class="w-100 mt-3"
                               value-as-date></b-form-datepicker>

            <b-form-datepicker
                v-if="s.dateRange === 'CUSTOM'"
                value-as-date
                class="w-100 mt-3"
                v-model="s.toDate"></b-form-datepicker>
          </div>
        </div>


        <div class="flex-grow-1 mt-1 mt-md-0 pt-3 mr-0 mr-md-2">
          <v-select :options="allProducts" v-model="s.productId" :reduce="p => p.id"
                    @input="loadOrders"
                    label="name" class="" placeholder="Select Product"></v-select>
        </div>

        <div class="mr-0 mr-md-2 mt-2 mt-md-3 d-flex justify-content-end">
          <b-button-group size="sm">
            <b-button @click="loadOrders" variant="primary">
              <font-awesome-icon icon="search"></font-awesome-icon>
              Search
            </b-button>
            <b-button @click="selectAll(true)" variant="success">
              <font-awesome-icon icon="check"></font-awesome-icon>
              Select ALL
            </b-button>
            <b-button @click="selectAll(false)" variant="warning">
              <font-awesome-icon icon="times"></font-awesome-icon>
              Remove ALL
            </b-button>
          </b-button-group>
        </div>


      </div>

      <div class="mr-0 mr-md-2 mt-2">
        <b-form-input v-model="textFilter"
                      size="lg"
                      placeholder="Search in Orders">
        </b-form-input>
      </div>
      <div class="mt-2 mt-md-3 d-flex flex-wrap">
        <order-display
            v-for="(order, idx) of filteredOrders"
            :key="order.id"
            :order="order"
            :slno="idx+1"
            :select="true"
        >
        </order-display>
      </div>
      <b-modal id="modal-2" title="Order Overview" hide-footer>
        <table class="table table-borderless">
          <thead>
          <tr>
            <th>Product</th>
            <th>Qty</th>
            <th>Price</th>
          </tr>
          </thead>
          <tbody v-for="cat of orderOverView" v-bind:key="cat.catName">
          <tr class="table-secondary">
            <td colspan="3">{{ cat.catName }}</td>
          </tr>
          <tr v-for="li of cat.products" :key="li.groupName">
            <td>
              <div style="max-width: 200px" class="d-flex flex-column">
                <div>
                  {{ li.prodName }}
                </div>
                <div>
                  <em>{{ li.howFine }}</em>
                </div>
              </div>
            </td>
            <td>{{ li.qty }}</td>
            <td>
              <rupee>{{ li.price }}</rupee>
            </td>
          </tr>
          </tbody>
          <tfoot>
          <tr class="table-secondary">
            <td colspan="2">Total</td>
            <td>
              <rupee>{{ totalOverViewPrice }}</rupee>
            </td>
          </tr>
          </tfoot>
        </table>
        <div class="d-flex justify-content-end">
          <b-button-group>
            <b-button @click="hide('modal-2')">
              <font-awesome-icon icon="times"></font-awesome-icon>
              Close
            </b-button>
            <b-button variant="primary" @click="printOverView">
              <font-awesome-icon icon="print"></font-awesome-icon>
              Print
            </b-button>
          </b-button-group>
        </div>

      </b-modal>
    </section>
  </form>
</template>
<script>
import axios from 'axios'
import Rupee from '@/components/rupee'
import OrderDisplay from './order-display'
import { AllOrderStatusList, NewCustomer, NewDeliveryArea, NewOrder } from '../util'
import add from 'date-fns/add'
import format from 'date-fns/format'

export default {
  name: 'AdminOrders',
  components: {OrderDisplay, Rupee},
  data: () => ({
    message: {
      customer: new NewCustomer(),
      mesg: ''
    },
    action: '',
    orders: [],
    type: 'ALL',
    addresses: [],
    orderStatus: 'OPEN',
    orderStatuses: ['OPEN'],
    paid: 'ALL',
    brand: 'ALL',
    orderStatusList: AllOrderStatusList,
    areas: [],
    allAreas: [],
    orderOverView: [],
    storeFilter: null,
    selectedAreas: [],
    allCustomers: [],
    textFilter: '',
    orderNum: '',
    payment: {customer: {}},
    discounts: [],
    s: {
      dateRange: 'THIRTY',
      fromDate: add(new Date(), {'days': -30}),
      toDate: new Date(),
      productId: null,
    },
    order: Object.assign({}, new NewOrder(null, null))
  }),
  async created () {
    await this.$store.dispatch('loadState')
    await this.$store.dispatch('loadBrands')
    let newOrder = this.$router.currentRoute.path.indexOf('new-order') > -1
    console.log('path = ', this.$router.currentRoute.path, 'newOrder?', newOrder)
    let customer = newOrder ? this.$route.params['customer'] : null
    let address = newOrder ? this.$route.params['address'] : null
    let ot = newOrder ? [] : (this.$route.params['type'] || 'OPEN').split(',')

    if (ot.length > 0 && !newOrder) {
      if (ot[0] !== 'UNPAID') {
        this.orderStatus = ot[0]
        this.orderStatuses = ot
      } else {
        this.orderStatus = 'DELIVERED'
        this.orderStatuses = ['DELIVERED']
        this.paid = 'UNPAID'
      }
    } else {
      this.orderStatuses = ['OPEN']
    }

    if (customer && address) {
      await this.$store.dispatch('createOrder', new NewOrder({id: address}, '', {id: customer}))
    }
    await this.loadOrders()
    let pds = await axios.get(`/api/admin/discount`)
    this.discounts = pds.data
  },
  computed: {
    customers () {
      return this.$store.state.searchCustomers
    },
    allProducts () {
      return this.$store.state.categories.flatMap(c => c.products)
    },
    filteredOrders () {
      let p = this.textFilter.toLowerCase()
      console.log('textFilter', p)
      if (p.length === 0) return this.orders
      return this.orders.filter(o =>
          o.customer.name.toLowerCase().indexOf(p) > -1 ||
          (o.customerAddress.person || '').toLowerCase().indexOf(p) > -1 ||
          o.customerAddress.address.toLowerCase().indexOf(p) > -1 ||
          o.netValue === +p ||
          o.orderNum === +p
      )
    },
    totalOverViewPrice () {
      return this.orders.map(o => o.netValue).reduce(function (accumulator, currentValue) {
        return accumulator + currentValue
      }, 0)
    },
    deliveryAction () {
      return `${process.env.VUE_APP_URL}/api/admin/deliveries/action`
    },
    orderUpdated () {
      return this.$store.state.orderUpdated
    },
    customerUpdated () {
      return this.$store.state.customerUpdated
    }
  },
  watch: {
    async orderUpdated (id) {
      console.log(`order with id ${id} is updated`)
      await this.loadOrders()
    },
    async customerUpdated (id) {
      console.log(`customer with id ${id} is updated`)
      await this.loadOrders()
    }
  },
  methods: {
    async submit (a) {
      this.action = a
      setTimeout(() => {
        this.$refs['form'].submit()

        if (a === 'invoice-schedule') {

          let om = this.orders
              .filter(o => o.orderStatus === 'OPEN' && o.selectedForDelivery)
              .map(o => `#${o.orderNum}`).join(',')

          this.$bvToast.toast(`Open Orders ${om} are now scheduled`, {
            title: 'Info',
            autoHideDelay: 30000,
            appendToast: true,
            variant: 'info'
          })
        }
        if(a === "amazon"){
          this.orders
              .filter(o => o.orderStatus === 'OPEN' && o.selectedForDelivery)
              .forEach(o => {
                o.modeOfShipment = "AMAZON"
              })
        }

      }, 100)
    },
    async calcDiscount () {
      setTimeout(async () => {
        let d = (await axios.post(`/api/admin/discount/apply?value=${this.itemValue}&code=${this.order.discountCode}`)).data
        this.order.discount = d.discount
      }, 100)
    },
    async loadAddress () {
      let r = await axios.get('/api/admin/address?customer_id=' + this.order.customer.id)
      let orders = (await axios.get(`/api/admin/orders?customerId=${this.order.customer.id}&time=120`)).data.orders
      this.addresses = r.data
      if (this.addresses.length === 1) {
        this.order.address = {
          id: this.addresses[0].id,
          address: this.addresses[0].address
        }
      }
      let fc = this.customers.filter(c => c.id === this.order.customer.id)
      //send review link only if > 1 order is delivered
      let co = orders.filter(o => o.orderStatus === 'DELIVERED').length
      this.order.sendReviewLink = co > 1 && fc.length > 0 && !fc[0].reviewDone
      console.log('sendReviewLink?', this.order.customer.id, orders.length, co, this.order.sendReviewLink)
    },
    async searchCustomers (search, loading) {
      if (search.length > 2) {
        loading(true)
        await this.$store.dispatch('searchCustomer', {search: search, area: this.area})
        loading(false)
      }
    },
    hide (what) {
      this.$bvModal.hide(what)
    },
    async newOrder () {
      await this.$store.dispatch('createOrder', NewOrder(NewDeliveryArea(), ''))
    },
    getOrderSearchQuery () {
      let area = `area=${this.selectedAreas.join(',')}`
      let oss = this.orderStatuses
      if (oss.length === 0) oss = ['ALL']
      let status = `status=${oss.join(',')}`
      let paid = `paid=${this.paid}`
      let brand = `brandId=${this.brand}`
      let type = `type=${this.type}`
      let time = `time=${this.s.dateRange}`
      let fd = `from=${format(this.s.fromDate, 'yyyy-MM-dd')}`
      let td = `to=${format(this.s.toDate, 'yyyy-MM-dd')}`
      let at = `x-auth-token=${this.$store.state.authToken}`
      let p = `productId=${this.s.productId}`
      let o = `orderNum=${this.orderNum}`
      return [
        area, status, paid, type, time, at, fd, td, p, o, brand
      ]
    },
    async selectAll (w) {
      this.orders.forEach(o => {
        if (w) {
          this.$set(o, 'selectedForDelivery', o.id)
        } else {
          this.$set(o, 'selectedForDelivery', null)
        }
      })
    },
    async loadOrders () {
      let q = this.getOrderSearchQuery()
      let json = await axios.get(
          `/api/admin/orders?${q.join('&')}`
      )

      this.orders = json.data.orders.map(o => {
        o.selectedForDelivery = false
        return o
      })
      this.textFilter = ''
      this.orderOverView = json.data.overview
      let newPath = '/admin/orders/' + this.orderStatuses.join(',')
      if (this.$router.currentRoute.path !== newPath)
        await this.$router.push(newPath)
    },
    async printOverView () {
      let q = this.getOrderSearchQuery()
      await window.open(`${process.env.VUE_APP_URL}/api/orders/overview?${q.join('&')}`)
    },
  }
}
</script>
<style>
.dark .vs__deselect svg {
  fill: white;
}

.dark .b-calendar-grid-body .text-dark {
  color: #cccccc !important;
}
</style>
