let req = 0
let loading = (w) => {
  if (w) {
    req++
  } else {
    req--
  }
  if (req > 0) {
    //console.log('show loading', req)
    document.getElementById('loading').style.visibility = 'visible'
  } else {
    //console.log('hide loading', req)
    document.getElementById('loading').style.visibility = 'hidden'
  }
}
let logError = (err) => {
  console.error('error in network request', err)
}
let NewOrder = function (area, brandId, c, address) {
  return {
    customer: c || {},
    deliveryArea: area,
    address: address || {
      fullAddress: '',
      person: ''
    },
    orderType: 'ADMIN',
    items: [],
    samples: [],
    notifyCustomer: true,
    sendPaymentLink: false,
    sendReviewLink: false,
    alreadyPaid: false,
    orderStatus: 'OPEN',
    adminNote: '',
    id: null,
    discount: 0,
    shipping: 0,
    modeOfShipment: 'WEFAST',
    brandId: brandId
  }
}
let NewRepeatOrder = function () {
  return {
    customer: {},
    address: {},
    product: {},
    variant: {},
    days: [],
    frequency: 'WEEKLY',
    brandId: null
  }
}
let NewReview = function () {
  return {
    customer: {
      id: null
    },
    links: '',
    comment: '',
    imageUrl: ''
  }
}
let NewCustomer = function () {
  return {
    name: '',
    id: null,
    reviewDone: false,
    deliveryArea: new NewDeliveryArea(),
    location: {
      'latitude': 12.898098455471294,
      'longitude': 77.56534737671858
    },
    brand: {}
  }
}
let NewBankAcc = function (n) {
  return {
    id: null,
    upi: '',
    name: n,
    bankName: '',
    mobileNo: '',
    email: '',
    accNo: '',
    ifsc: '',
  }
}
let NewDeliveryArea = function (id) {
  return {
    name: '',
    id: id,
    minOrderValue: 150
  }
}
let MakeReturn = function () {
  return {
    customerId: null,
    customerName: '',
    areaName: '',
    areaId: null,
    orderId: null,
    bottles: 0,
    bottlesHalf: 0,
    bottlesSmall: 0,
    bottlesMedium: 0,
    covers: 0,
    coverAmt: 0,
    bottleAmt: 0,
    bottleSmallAmt: 0,
    bottleHalfAmt: 0,
    bottleMediumAmt: 0,
    totalAmt: 0,
    image: ''
  }
}
let AddProduct = function () {
  return {
    name: '',
    webName: '',
    category: {},
    taxPct: {},
    weighingUnit: 'KG',
    mainImage: '',
    color: '#000000',
    images: [],
    videos: [],
    keywords: [],
    printDescription: '',
    description: '',
    ingredients: [],
    variantTypes: [],
    variantMatrix: {},
    variantMatrixSelect: {},
    isSample: false,
    googleProductCode: '',
    fbProductCode: '',
    variantList: [{
      id: null,
      name: '',
      weight: 0.0,
      moq: 1,
      price: 0.0,
      offerPrice: 0.0,
      active: true,
      images: [],
      color: null,
      matrix: {}
    }],
    id: null,
    subTitle: '',
    selectTexture: false,
    tags: []
  }
}

let NewSubscription = function (variant, subscribeDays) {
  return {
    variantId: variant.id,
    pausedFlag: false,
    subscriptionDays: (subscribeDays || []).map(d => {
      return {id: null, qty: 0, subscribedDay: d, active: true}
    }),
    id: null,
    subscribed: false
  }
}
let NewWishList = function (product, variant, me) {
  let def = {
    productId: product.id,
    notified: false,
    favourite: false,
    toNotify: false,
    variantId: variant.id,
    customerId: me.id,
  }
  let prodInWishlist = (me.wishlistProducts || [])
    .filter(w => w.product.id === product.id && w.variant && w.variant.id === variant.id)

  if (prodInWishlist.length > 0) {
    return {
      productId: prodInWishlist[0].product.id,
      variantId: prodInWishlist[0].variant.id,
      customerId: prodInWishlist[0].customer.id,
      favourite: prodInWishlist[0].favourite,
      toNotify: prodInWishlist[0].toNotify,
      notified: prodInWishlist[0].notified,
    }
  }
  return def
}
let NewDelivery = function () {
  return {da: {}, orders: [], orderList: []}
}
let AllOrderStatusList = [
  {id: 'ALL', name: 'All Orders'},
  {id: 'DRAFT', name: 'Draft Orders'},
  {id: 'OPEN', name: 'Open Orders'},
  {id: 'SCHEDULED', name: 'Scheduled Orders'},
  {id: 'WAITING_FOR_PICKUP', name: 'Waiting for Pickup'},
  {id: 'SHIPPED', name: 'Out-For Delivery Orders'},
  {id: 'CANCELLED', name: 'Cancelled Orders'},
  {id: 'DELIVERED', name: 'Delivered Orders'},
]

let titleCase = (str) => {
  // Step 1. Lowercase the string
  str = str.toLowerCase()
  // str = "I'm a little tea pot".toLowerCase();
  // str = "i'm a little tea pot";

  // Step 2. Split the string into an array of strings
  str = str.split(' ')
  // str = "i'm a little tea pot".split(' ');
  // str = ["i'm", "a", "little", "tea", "pot"];

  // Step 3. Create the FOR loop
  for (var i = 0; i < str.length; i++) {
    str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    /* Here str.length = 5
      1st iteration: str[0] = str[0].charAt(0).toUpperCase() + str[0].slice(1);
                     str[0] = "i'm".charAt(0).toUpperCase()  + "i'm".slice(1);
                     str[0] = "I"                            + "'m";
                     str[0] = "I'm";
      2nd iteration: str[1] = str[1].charAt(0).toUpperCase() + str[1].slice(1);
                     str[1] = "a".charAt(0).toUpperCase()    + "a".slice(1);
                     str[1] = "A"                            + "";
                     str[1] = "A";
      3rd iteration: str[2] = str[2].charAt(0).toUpperCase()   + str[2].slice(1);
                     str[2] = "little".charAt(0).toUpperCase() + "little".slice(1);
                     str[2] = "L"                              + "ittle";
                     str[2] = "Little";
      4th iteration: str[3] = str[3].charAt(0).toUpperCase() + str[3].slice(1);
                     str[3] = "tea".charAt(0).toUpperCase()  + "tea".slice(1);
                     str[3] = "T"                            + "ea";
                     str[3] = "Tea";
      5th iteration: str[4] = str[4].charAt(0).toUpperCase() + str[4].slice(1);
                     str[4] = "pot".charAt(0).toUpperCase() + "pot".slice(1);
                     str[4] = "P"                           + "ot";
                     str[4] = "Pot";
      End of the FOR Loop*/
  }

  // Step 4. Return the output
  return str.join(' ') // ["I'm", "A", "Little", "Tea", "Pot"].join(' ') => "I'm A Little Tea Pot"
}
let makeImgUrl = (p) => {
  //https://ms-profiles.objectstore.e2enetworks.net/305578-73473067-a636-40c9-a8e3-dbfc9d6927c4-IMG-20201222-172032.png
  //https://themilletstore.imgix.net/862844-wfz-healthy-vegetarian-balls-with-chickpea-peanut-butter-and-coconut-in-picture-id1210416268.jpg?ar=1&fit=clip&height=1024&ixlib=java-2.3.2&w=181&width=1024
  if (p.indexOf('http') === 0) {
    if (p.indexOf('https://ms-profiles') === 0) {
      let imgName = p.replace('https://ms-profiles.objectstore.e2enetworks.net/', '')
      return `https://themilletstore.imgix.net/${imgName}?ar=1&fit=clip&height=1024&ixlib=java-2.3.2&w=181&width=1024&auto=format`
    }
    return p
  }
  return `${process.env.VUE_APP_URL}/public/image/${p}?size=128`
}

export {
  loading, logError, NewOrder, AllOrderStatusList, NewWishList, NewDelivery, AddProduct,
  NewCustomer, NewDeliveryArea, NewSubscription, MakeReturn, NewRepeatOrder, NewReview, NewBankAcc,
  titleCase, makeImgUrl
}
