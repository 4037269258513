<template id="admin-categories">
  <section>

    <b-tabs>
      <b-tab title="Collections">
        <div class="p-3">
          <div class="d-flex my-3 justify-content-between">
            <h2>Collections</h2>
            <div class="flex-grow-1">&nbsp;</div>
            <b-form-group label="Sort By" >
              <b-form-select v-model="colSort">
                <option value="NAME">Name</option>
                <option value="ORDER">Seq No</option>
              </b-form-select>
            </b-form-group>
          </div>
          <div class="d-flex flex-wrap">
            <b-card title="New Collection" class="m-2">

              <b-card-body>
                <b-form-group label="Name of Collection">
                  <b-form-input v-model="col.name" type="text" required placeholder="Name"></b-form-input>
                </b-form-group>

                <b-form-group label="Seq No">
                  <b-form-input v-model="col.seqNo" type="number" required placeholder="Seq No"></b-form-input>
                </b-form-group>

                <b-form-group label="Parent Collection">
                  <b-form-select v-model="col.parentId">
                    <option :value="null">No Parent</option>
                    <option v-for="c of collections" :key="c.id" :value="c.id">{{ c.name }}</option>
                  </b-form-select>
                </b-form-group>

                <b-form-group label="Brand">
                  <b-form-select v-model="col.brand.id">
                    <option v-for="b of brands" :key="b.id" :value="b.id">{{ b.name }}</option>
                  </b-form-select>
                </b-form-group>

                <b-form-checkbox v-model="col.frontPage" name="check-button" switch>
                  Font Page Collection?
                </b-form-checkbox>
              </b-card-body>

              <b-card-footer>
                <b-button href="#" variant="primary" @click="saveCollection(col)">Save</b-button>
              </b-card-footer>

            </b-card>

            <b-card v-for="c in sortedCollections" :title="c.name" :key="c.id" class="m-2">
              <b-card-body>
                <b-form-group label="Name of Collection">
                  <b-form-input
                      v-model="c.name" type="text" required placeholder="Name"></b-form-input>
                </b-form-group>
                <b-form-group label="Seq No">
                  <b-form-input
                      v-model="c.seqNo" type="number" required placeholder="Seq No"></b-form-input>
                </b-form-group>

                <b-form-group label="Parent Collection">
                  <b-form-select v-model="c.parentId">
                    <option :value="null">No Parent</option>
                    <option v-for="c of collections" :key="c.id" :value="c.id">{{ c.name }}</option>
                  </b-form-select>
                </b-form-group>
                <b-form-checkbox v-model="c.frontPage" name="check-button" switch>
                  Font Page Collection?
                </b-form-checkbox>
                <div class="mt-5">
                  <display-brand :id="c.brand.id"></display-brand>
                </div>
              </b-card-body>

              <b-card-footer class="d-flex justify-content-end">
                <b-button href="#" variant="primary" @click="saveCollection(c)">Save</b-button>
                &nbsp;
                <b-button href="#" variant="danger" @click="deleteCollection(c)">Delete</b-button>
              </b-card-footer>


            </b-card>
          </div>
        </div>
      </b-tab>
      <b-tab title="Categories">
        <div class="p-3">
          <div class="d-flex justify-content-between flex-column flex-md-row mb-3 mr-md-3">
            <h4>Categories</h4>
            <div class="d-flex justify-content-between flex-column flex-md-row">

              <div class="mt-3 mt-md-0 text-right mr-md-2">
                <b-form-input type="text" v-model="filter" placeholder="Search......"></b-form-input>
              </div>
              <div class="mt-3 mt-md-0 text-right mr-md-2">
                <b-form-select type="text" v-model="catSort" @change="loadCategories">
                  <option value="name">Name</option>
                  <option value="order">Order</option>
                  <option value="updated">Latest</option>
                </b-form-select>
              </div>
              <div class="mt-3 mt-md-0 text-right mr-md-2">
                <b-form-select type="text" v-model="brandFilter" @change="loadCategories">
                  <option value="">All Brands</option>
                  <option v-for="b of brands" :key="b.id" :value="b.id">{{ b.name }}</option>
                </b-form-select>
              </div>
              <div class="mt-3 mt-md-0 text-right">
                <b-button v-b-modal.modal-1 variant="info">
                  <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
                  New Category
                </b-button>
              </div>
            </div>
          </div>
          <div class="d-flex flex-wrap flex-column flex-md-row">
            <div class="card bg-transparent mt-2 mr-0 mr-md-2" style="min-width: 300px" v-for="c of filterCategories"
                 :key="c.id">
              <div class="card-header d-flex justify-content-start align-content-center align-items-center">
                <b-img-lazy v-if="c.imageUrl" :src="makeImgUrl(c.imageUrl)" width="30"/>
                <div class="ml-3">{{ c.name }}</div>
              </div>

              <div class="card-body">
                <span class="mt-2">Order</span>
                <div class="d-flex mb-3">
                  <b-form-input
                      size="lg"
                      type="range"
                      step="1"
                      min="-100" max="100" v-model="c.ordering" @change="updateCat(c)"></b-form-input>
                  <span>{{ c.ordering }}</span>
                </div>

                <b-form-checkbox v-model="c.showInHomePage" name="check-button" switch @change="updateCat(c)">
                  Show in HomePage?
                </b-form-checkbox>

                <b-form-checkbox v-model="c.featuredCategory" name="check-button" switch @change="updateCat(c)">
                  Feature in Home Page?
                </b-form-checkbox>

                <b-form-checkbox v-model="c.showInWebsite" name="check-button" switch @change="updateCat(c)">
                  Show in Website?
                </b-form-checkbox>

                <b-form-checkbox v-model="c.indiaShipping" name="check-button-2" switch @change="updateCat(c)">
                  All India Shipping?
                </b-form-checkbox>

                <div class="list-group my-3" v-if="c.brands && c.brands.length > 0">
                  <div class="list-group-item list-group-item-dark">Brands</div>
                  <div class="list-group-item" v-for="b of c.brands" :key="b">
                    <display-brand :id="b"></display-brand>
                  </div>
                </div>

                <div class="mb-2 mt-2 border-bottom border-left border-right border-top p-2 w-100 w-md-33"
                     v-if="c.description">
                  <span class="">Description</span>
                  <div class="" v-html="c.description"></div>
                </div>
                <div class="list-group my-3">
                  <div class="list-group-item list-group-item-dark">Products</div>

                  <div class="list-group-item" v-for="p of c.products" :key="p.id">{{ p.name }}</div>
                </div>
                <div class="list-group my-3" v-if="c.productCommonImages && c.productCommonImages.length > 0">
                  <div class="list-group-item list-group-item-dark">Common Product Images</div>

                  <a class="list-group-item" v-for="(p, idx) of c.productCommonImages" :key="p" :href="p"
                     target="_blank">Image {{ idx + 1 }}</a>
                </div>
              </div>
              <div class="card-footer text-right">
                <div class="btn-group">

                  <new-product :product="product" :category="c" v-on:product-saved="loadCategories">
                    <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
                  </new-product>
                  <button class="btn btn-sm btn-primary" @click.prevent="editCategory(c)">
                    <font-awesome-icon :icon="['far','edit']"></font-awesome-icon>
                  </button>
                  <button class="btn btn-sm btn-danger" @click.prevent="deleteCategory(c)"
                          :disabled="c.products.length > 0">
                    <font-awesome-icon :icon="['far','times']"></font-awesome-icon>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </b-tab>
    </b-tabs>
    <b-modal id="modal-1" title="New Category" @ok="save">
      <b-form>
        <b-form-group label="Name of Category">
          <b-form-input v-model="cat.name" type="text" required placeholder="Name"></b-form-input>
        </b-form-group>
        <b-form-group label="Display Sequence">
          <b-form-input v-model="cat.ordering" type="number" required placeholder="Display Sequence"></b-form-input>
        </b-form-group>
        <b-form-checkbox v-model="cat.showInHomePage" name="check-button" switch class="mt-2 mb-2">
          Show in HomePage?
        </b-form-checkbox>
        <b-form-checkbox v-model="cat.indiaShipping" name="check-button" switch class="mt-2 mb-2">
          All India Shipping?
        </b-form-checkbox>

        <b-form-group label="Brands">
          <b-form-checkbox-group
              id="checkbox-group-1"
              v-model="cat.brands"
              :options="brands"
              value-field="id"
              text-field="name"
              name="flavour-1"
          ></b-form-checkbox-group>
        </b-form-group>

        <div class="mt-3 d-flex flex-column">
          <h5 class="mb-3">Description</h5>
          <vue-editor
              :editorToolbar="customToolbar"
              v-model="cat.description"></vue-editor>
        </div>
        <div class="mt-2">
          <file-upload v-model="cat.imageUrl" :resize="true" title="Category Image"></file-upload>
        </div>

        <b-form-group label="Best Before">
          <b-form-input v-model="cat.bestBefore" type="text" required placeholder="Best Before"></b-form-input>
        </b-form-group>
        <hr>
        <file-upload v-model="img" :resize="true" @input="addNewImage" title="Common Product Images"></file-upload>
        <div class="d-flex flex-column justify-content-start mt-2 mb-2">

          <small>Drag and Drop to Rearrange</small>
          <draggable v-model="cat.productCommonImages" group="people">

            <transition-group>
              <div
                  class="d-flex w-100 justify-content-between align-items-center align-content-center m-2 border-info p-2 border"
                  v-for="img of cat.productCommonImages" :key="img">
                <b-img-lazy class="" :src="makeImgUrl(img)" width="80"></b-img-lazy>
                <div>
                  <a @click.prevent="removeImage(img)"
                     href="#"
                     class="btn btn-outline-danger btn-sm"
                     style="cursor: pointer">
                    <font-awesome-icon icon="times" size="2x"></font-awesome-icon>
                  </a>
                </div>
              </div>
            </transition-group>
          </draggable>

        </div>

      </b-form>
    </b-modal>
  </section>
</template>
<script>
import axios from 'axios'
import NewProduct from '@/components/new-product'
import FileUpload from '@/components/file-upload'
import DisplayBrand from '@/components/display-brand'
import {VueEditor} from "vue2-editor";
import draggable from "vuedraggable";
import _ from 'lodash'


export default {
  name: 'AdminCategories',
  components: {draggable, NewProduct, FileUpload, VueEditor, DisplayBrand},
  data: () => ({
    colSort: 'ORDER',
    img: '',
    categories: [],
    collections: [],
    taxes: [],
    col: {brand: {}},
    cat: {productCommonImages: []},
    product: {id: null, category: {id: null}, taxPct: {id: null}},
    filter: "",
    catSort: 'name',
    brandFilter: '',
    customToolbar: [
      [{'header': [1, 2, 3, 4, 5, 6, false]}],
      ["bold", "italic", "underline"],
      [{list: "ordered"}, {list: "bullet"}],
      ['clean']
    ]
  }),
  async created() {
    await this.loadTaxes()
    await this.loadCategories()
  },
  computed: {
    brands() {
      return this.$store.state.brands
    },
    filterCategories: function () {
      let find = this.filter.toLowerCase();
      let f = this.$store.state.categories;
      if (find.length > 0)
        f = this.$store.state.categories.filter(c => c.name.toLowerCase().indexOf(find) > -1)

      if (this.brandFilter.length > 0)
        f = f.filter(c => c.brands != null && c.brands.indexOf(this.brandFilter) > -1)

      return f
    },
    sortedCollections: function () {
      let fn = this.colSort === 'NAME' ? c => c.name.toLowerCase() : c => c.seqNo;
      return _.sortBy(this.collections, fn)
    }
  },
  methods: {

    removeImage(img) {
      let start = this.cat.productCommonImages.indexOf(img)
      console.log('remove', img, 'index', start)
      this.cat.productCommonImages.splice(start, 1)
    },
    addNewImage(a) {
      setTimeout(() => {
        if (!this.cat.productCommonImages) {
          this.cat.productCommonImages = []
        }
        this.cat.productCommonImages.push(a)
      }, 1000)
    },
    getBrand(b) {
      return this.brands.filter(ba => ba.id === b)[0].name
    },

    async loadCategories() {
      await this.$store.dispatch("loadCategories", this.catSort)
      await this.$store.dispatch("loadCollections")
      this.collections = this.$store.state.collections.map(c => ({...c}))
    },

    makeImgUrl(p) {
      //https://ms-profiles.objectstore.e2enetworks.net/305578-73473067-a636-40c9-a8e3-dbfc9d6927c4-IMG-20201222-172032.png
      //https://themilletstore.imgix.net/862844-wfz-healthy-vegetarian-balls-with-chickpea-peanut-butter-and-coconut-in-picture-id1210416268.jpg?ar=1&fit=clip&height=1024&ixlib=java-2.3.2&w=181&width=1024
      if (p.indexOf('http') === 0) {
        if (p.indexOf("https://ms-profiles") === 0) {
          let imgName = p.replace("https://ms-profiles.objectstore.e2enetworks.net/", "")
          return `https://themilletstore.imgix.net/${imgName}?ar=1&fit=clip&height=1024&ixlib=java-2.3.2&w=181&width=1024&auto=format`
        }
        return p;
      }
      return `${process.env.VUE_APP_URL}/public/image/${p}?size=128`
    },
    async loadTaxes() {
      let json = await axios.get('/api/admin/taxes')
      this.taxes = json.data
    },
    async saveCollection(c) {
      await axios.post('/api/admin/collections', c)
      await this.loadCategories()
      this.col = {brand: {}}
    },
    async deleteCollection(c) {
      await axios.delete(`/api/admin/collections?id=${c.id}`)
      await this.loadCategories()
    },
    async save() {
      await axios.post('/api/admin/categories', this.cat)
      await this.loadCategories()
      this.cat = {}
    },
    async updateCat(p) {
      await axios.post('/api/admin/categories', p)
      await this.loadCategories()
    },
    async dec(p) {
      p.ordering--
      await axios.post('/api/admin/categories', p)
      await this.loadCategories()
    },
    async inc(p) {
      p.ordering++
      await axios.post('/api/admin/categories', p)
      await this.loadCategories()
    },
    async editCategory(p) {
      this.cat = Object.assign({}, p)
      delete this.cat['products']
      this.$bvModal.show('modal-1')
    },
    async deleteCategory(p) {
      await axios.delete(`/api/admin/categories/${p.id}`)
      await this.loadCategories()
    }
  }
}
</script>
<style scoped>
@media (min-width: 576px) {
  .w-md-50 {
    max-width: 50vw;
  }

  .w-md-33 {
    max-width: 33vw;
  }
}
</style>