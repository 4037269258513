<template id="admin-products">

  <div class="product-card card mt-3 mr-md-3">
    <div class="card-header">
      {{ product.name }}
      <div class="badge" :style="'background-color:'+product.color+';color: #fff;'">
        {{ product.color }}
      </div>
      <b-badge class="ml-2">Order# {{ product.ordering }}</b-badge>
    </div>
    <div class="card-body d-flex flex-column">

      <div class="d-flex justify-content-start mb-3 flex-wrap" v-if="product.imagesToDisplay && product.imagesToDisplay.length > 0">
        <b-img-lazy class="mr-2 border-info border" v-for="img of product.imagesToDisplay" :key="img" :src="img"
                    thumbnail
                    width="100"></b-img-lazy>
      </div>

      <div class="d-flex justify-content-start mb-3" v-if="product.videos && product.videos.length > 0">
        <a class="mr-2 border-info border p-2"
           v-for="img of product.videos"
           :key="img"
           :href="img" target="_blank">
          <font-awesome-icon icon="film"></font-awesome-icon>
          View
        </a>
      </div>

      <div class="p-2 mt-2">
        <h4>Product Category</h4>
        {{ product.category.name }}
      </div>
      <div class="p-2 mt-2" v-if="product.webName">
        <h4>Website Product Name</h4>
        {{ product.webName }}
      </div>
      <div class="p-2 mt-2" v-if="product.collections && product.collections.length > 0">
        <h4>Collections</h4>
        <b-badge v-for="(ing, idx) of product.collections" :key="idx" class="ml-1">{{ collection[ing] }}</b-badge>
      </div>
      <div class="p-2 mt-2" v-if="product.ingredients && product.ingredients.length > 0">
        <h4>Ingredients</h4>
        <b-badge v-for="(ing, idx) of product.ingredients" :key="idx" class="ml-1">{{ ing }}</b-badge>
      </div>
      <div class="p-2 mt-2" v-if="product.keywords && product.keywords.length > 0">
        <h4>KeyWords</h4>
        <b-badge v-for="(ing, idx) of product.keywords" :key="idx" class="ml-1">{{ ing }}</b-badge>
      </div>

      <div class="p-2 mt-2" v-if="product.tags && product.tags.length > 0">
        <h4>Tags</h4>
        <b-badge v-for="(ing, idx) of product.tags" :key="idx" class="ml-1" variant="primary">{{ ing }}</b-badge>
      </div>

      <table class="table table-sm table-borderless mb-2 mt-2" v-if="product.variantTypes.length > 0">
        <thead>
        <tr>
          <th>Variant Type</th>
          <th>Variant Options</th>
        </tr>
        </thead>
        <tbody>
        <tr v-for="vt of product.variantTypes" :key="vt">
          <td>
            <div class="d-flex">
              {{ vt }}
              <a @click.prevent="editVariantName(vt)" class="ml-2 btn btn-sm btn-outline-primary">
                <font-awesome-icon icon="pencil"></font-awesome-icon>
              </a>
            </div>
          </td>
          <td>
            <b-badge v-for="(ing, idx) of product.variantMatrix[vt]" :key="idx" class="ml-1" variant="primary">
              {{ ing }}
            </b-badge>
          </td>
        </tr>
        </tbody>
      </table>

      <b-list-group>
        <b-list-group-item v-for="v of product.variants" :key="v.id"
                           class="p-1 p-md-2" :variant="listVariant(v)">
          <div class="d-flex justify-content-between">
            <div class="d-flex flex-column">
              <h5>{{ v.name || '-na-' }}</h5>

              <div v-for="(v,k) of v.matrix" :key="k">{{ k }} - {{ v }}</div>
            </div>
            <div>
              <span><font-awesome-icon :icon="['far','cart-plus']"></font-awesome-icon> </span>
              <span class="ml-1" style="text-decoration: line-through"><rupee>{{ v.price }}</rupee></span>
              <span class="ml-1"><rupee>{{ v.offerPrice || v.price }}</rupee></span>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-2">
            <div class="btn-group btn-group-lg">
              <button class="btn"
                      v-bind:class="{'btn-info': v.active, 'btn-danger': !v.active}"
                      @click.prevent="inactiveVariant(v)">
                Active
                <font-awesome-icon :icon="['far', 'check-circle']" v-if="v.active"></font-awesome-icon>
                <font-awesome-icon :icon="['far', 'times-circle']" v-if="!v.active"></font-awesome-icon>
              </button>
              <button class="btn"
                      v-bind:class="{'btn-success': !v.stockOut, 'btn-warning': v.stockOut}"
                      @click.prevent="stockOutVariant(v)">
                Stock
                <font-awesome-icon :icon="['far', 'check-square']" v-if="!v.stockOut"></font-awesome-icon>
                <font-awesome-icon :icon="['far', 'times-hexagon']" v-if="v.stockOut"></font-awesome-icon>
              </button>
            </div>
          </div>

        </b-list-group-item>
      </b-list-group>

    </div>
    <div class="card-footer">
      <div class="d-flex justify-content-center flex-column flex-md-row">

        <b-button-group size="sm">

          <new-product :product="copy()" :edit="true">
            <font-awesome-icon :icon="['far','edit']"></font-awesome-icon>
            <span class="d-none d-md-inline ml-1">Edit</span>
          </new-product>
          <b-button
              @click="printProd()"
              variant="primary">
            <font-awesome-icon :icon="['far','print']"></font-awesome-icon>
            <span class="d-none d-md-inline ml-1">Print</span>
          </b-button>

          <b-button
              variant="danger"
              @click.prevent="deleteProduct()">
            <font-awesome-icon :icon="['far', 'times-circle']"></font-awesome-icon>
            <span class="d-none d-md-inline ml-1">Delete</span>
          </b-button>
<!--          <b-button-->
<!--              variant="success"-->
<!--              @click.prevent="shareProduct()">-->
<!--            <font-awesome-icon icon="share"></font-awesome-icon>-->
<!--            <span class="d-none d-md-inline ml-1">Share</span>-->
<!--          </b-button>-->
          <b-button
              variant="info"
              @click.prevent="editRecipe()">
            <font-awesome-icon icon="cogs"></font-awesome-icon>
            <span class="d-none d-md-inline ml-1">Recipe</span>
          </b-button>
        </b-button-group>
      </div>
    </div>

  </div>

</template>
<script>
import _ from 'lodash'
import axios from 'axios'
import NewProduct from '@/components/new-product'
import Rupee from '@/components/rupee'

export default {
  name: 'AdminProductView',
  components: {NewProduct, Rupee},
  props: ['product'],
  data: () => ({
    filter: '',
    edit: false,
    imgFilter: false,

    cat: null,
    prodSubTitle: '',
    customToolbar: [
      ['bold', 'italic', 'underline'],
      [{list: 'ordered'}, {list: 'bullet'}]
    ],
    recipe: {},
    collection: {}
  }),
  async created() {
    this.prodSubTitle = this.product.subTitle
    this.$store.state.collections.forEach(c => {
      this.collection[c.id] = c.name
    })
  },
  async updated() {
  },
  computed: {
    singleVariantProduct() {
      return this.product.variants.length === 1
    },
  },
  methods: {

    async shareProduct() {
      await this.$store.dispatch('setProductShare', {id: this.product.id})
    },
    async editRecipe() {
      console.log('editing, the recipe of', this.product)
      await this.$store.dispatch('setEditProductRecipe', {id: this.product.id})
    },
    listVariant(v) {
      return v.stockOut || !v.active ? 'warning' : ''
    },
    copy() {
      return _.cloneDeep(this.product)
    },
    async editVariantName(vt) {
      let newVt = prompt('edit variant', vt)
      if (newVt) {
        await axios.patch(`/api/admin/products/variant?id=${this.product.id}&old=${vt}&new=${newVt}`)
        await this.$store.dispatch('loadProducts')
      }
    },
    async stockOut() {
      await axios.put(`/api/admin/products/${this.product.id}?action=stockout`)
      await this.$store.dispatch('loadProducts')
    },
    async updateProduct() {
      await axios.post(`/api/admin/products/${this.product.id}?action=update`, {
        subTitle: this.prodSubTitle,
        description: this.product.description
      })
    },
    async deleteProduct() {
      if (confirm('really delete?')) {
        await axios.delete(`/api/admin/products/${this.product.id}`)
        await this.$store.dispatch('loadProducts')
      }
    },
    async stockOutVariant(v) {
      await axios.put(`/api/admin/products/variant/${v.id}?status=stockout`)
      await this.$store.dispatch('loadProducts')
    },
    async inactiveVariant(v) {
      await axios.put(`/api/admin/products/variant/${v.id}?status=inactive`)
      await this.$store.dispatch('loadProducts')
    },
    async printProd() {
      await this.$router.push(
          {'name': 'product-print-1', 'params': {'productId': this.product.id}}
      )
    }

  }
}
</script>
<style scoped>
body.dark .bg-bw {
  background-color: #2f2f2f !important;
}

body.light .bg-bw {
  background-color: #ffffff !important;
}

@media (min-width: 1024px) {
  .product-card {
    width: 30vw;
  }
}

@media (min-width: 577px) and  (max-width: 1023px) {
  .product-card {
    width: 40vw;
  }
}

@media (max-width: 576px) {
  .product-card {
    width: 100%;
  }
}
</style>