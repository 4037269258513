<template>
  <div class="card mt-3 mr-md-3 order-display m-w-100 p-1"
       v-bind:class="{'subs': order.orderType === 'SUBSCRIPTION'}">
    <div class="card-header" style="padding: .5rem" v-if="!hideCustomer">
      <div class="d-flex flex-column">

        <div class="d-flex align-items-center align-content-center">
          <b-link :to="customerPage" class="d-flex w-100">

            <customer-avatar :customer="order.customer" size="3.5rem"></customer-avatar>

            <div class="ml-2 d-flex flex-column text-toggle flex-grow-1">
              <div class="d-flex justify-content-between align-content-center align-items-center mb-1">
                <div class="name" v-if="order.customerAddress.person">{{ order.customerAddress.person }}</div>
                <div class="name" v-else>{{ order.customer.name }}</div>

              </div>
              <div v-if="order.customerAddress" class="address">
                {{ order.customerAddress.fullAddress }}
              </div>
            </div>
            <display-brand v-if="order.brand" :id="order.brand.id" :name="false" :border="false"
                           :width="32"></display-brand>

          </b-link>

          <div class="ml-2">
           <dont-disturb :customer="order.customer"></dont-disturb>
          </div>
        </div>
        <div class="d-flex justify-content-start align-items-center align-content-center mt-3" style="font-size: small">
          <b-badge class="mr-2" pill variant="primary">{{ slno }}</b-badge>
          <span class="text-muted">#{{ order.orderNum }}</span>
          <span class="text-muted align-self-end text-right flex-grow-1">{{ order.orderDateStr }}</span>
        </div>
      </div>
    </div>
    <div class="card-body" style="padding: .5rem">
      <div class="list-group list-group-flush">

        <div class="list-group-item d-flex justify-content-start align-items-center align-content-center"
             v-for="(li, index) of order.lineItems"
             v-bind:key="li.id">
          <span class="badge badge-pill badge-primary">{{ index + 1 }}</span>
          <div class="d-flex flex-column ml-3 flex-fill">
            <div style="max-width: 300px;">{{ li.product.name }}
              <span class="ml-1 mr-3">x {{ li.qty }}</span>
            </div>
            <div class="d-flex flex-fill">

              <div style="max-width: 200px;">
                <span v-for="(v,k) of li.variant.matrix" :key="k">
                  {{ v }}
                </span>
              </div>

              <div class="d-flex flex-grow-1 justify-content-end">
                <rupee>{{ li.netValue }}</rupee>
              </div>
            </div>
          </div>

        </div>
        <div class="list-group-item d-flex justify-content-start align-items-center align-content-center flex-fill"
             style="padding: .5rem"
             v-for="(li, index) of order.samples"
             v-bind:key="li.id">
          <span class="badge badge-pill badge-primary">{{ order.lineItems.length + index + 1 }}</span>
          <div class="d-flex flex-column ml-3 flex-fill">
            <div class="d-flex justify-content-between">
              <div class="pb-1 text-capitalize">
                {{ li.productName }}
              </div>
              <rupee>{{ li.price }}</rupee>
            </div>
            <div style="max-width: 300px">
              {{ li.qty }}
            </div>
          </div>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          Send Review Link?
          <span v-if="order.sendReviewLink" class="text-success">
            <font-awesome-icon icon="check"></font-awesome-icon> Yes
          </span>
          <span v-else class="text-danger">
            <font-awesome-icon icon="times"></font-awesome-icon> No
          </span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center"
             v-if="order.discount>0">
          Discount ({{ order.discountCode }})
          <span class="badge badge-pill">- <rupee>{{ order.discount }}</rupee></span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center"
             v-if="order.walletAmt>0">
          Wallet
          <span class="badge badge-pill">- <rupee>{{ order.walletAmt }}</rupee></span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center"
             v-if="order.shipping>0">
          Shipping
          <span class="badge badge-pill"><rupee>{{ order.shipping }}</rupee></span>
        </div>
        <div class="list-group-item d-flex justify-content-between align-items-center">
          Total
          <span class="badge badge-pill"><rupee>{{ order.netValue }}</rupee></span>
        </div>

        <div class="list-group-item d-flex justify-content-between align-items-center" v-if="order.trackingNumber">
          Tracking
          <a :href="order.trackingUrl" target="_blank">{{ order.trackingNumber }}</a>
        </div>

      </div>
      <div class="d-flex justify-content-center flex-column" style="max-width: 400px; text-align: center">
        <b-alert variant="primary" show class="mt-2">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-content-center align-items-center">
              <font-awesome-icon v-if="order.orderNote" :icon="['fas', 'exclamation-triangle']"></font-awesome-icon>
              <div  class="ml-2">
                <b-form-input size="sm" v-if="editCustomerNote" v-model="customerNote" placeholder="Enter Customer Note"></b-form-input>
                <span v-else style="max-width: 400px" @dblclick="editNotes('customer_note')"> {{ order.orderNote }}</span>
              </div>
            </div>
            <div>
              <b-button size="sm" variant="outline-info" v-if="editCustomerNote" @click="saveNote('customer_note')">
                <font-awesome-icon icon="save" ></font-awesome-icon>
              </b-button>
              <b-button size="sm" variant="outline-info" v-else @click="editNotes('customer_note')">
                <font-awesome-icon icon="edit" ></font-awesome-icon>
              </b-button>
            </div>
          </div>
        </b-alert>
        <b-alert variant="info" show class="mt-2">
          <div class="d-flex justify-content-between">
            <div class="d-flex align-content-center align-items-center">
              <font-awesome-icon v-if="order.adminNote" :icon="['fas', 'exclamation-circle']"></font-awesome-icon>
              <div  class="ml-2">
                <b-form-input size="sm" v-if="editAdminNote" v-model="adminNote" placeholder="Enter Admin Note"></b-form-input>
                <span v-else style="max-width: 400px" @dblclick="editNotes('admin_note')"> {{ order.adminNote }}</span>
              </div>
            </div>
            <div>
              <b-button size="sm" variant="outline-info" v-if="editAdminNote" @click="saveNote('admin_note')">
                <font-awesome-icon icon="save" ></font-awesome-icon>
              </b-button>
              <b-button size="sm" variant="outline-info" v-else @click="editNotes('admin_note')">
                <font-awesome-icon icon="edit" ></font-awesome-icon>
              </b-button>
            </div>
          </div>
        </b-alert>
      </div>
    </div>
    <div class="card-footer">


      <div class="d-flex flex-column align-content-center justify-content-center">


        <div class="d-flex justify-content-center mb-3">
          <div class="mr-1 ml-1">&nbsp;</div>
          <shipment-mode :order="order"></shipment-mode>
          <div class="mr-1 ml-1">&nbsp;</div>
          <order-status :order="order"></order-status>
          <div class="mr-1 ml-1">&nbsp;</div>
          <payment-status :order="order"></payment-status>
        </div>
        <div class="d-flex justify-content-between align-items-center align-content-center" v-if="!hideFooter">

          <div class="d-flex flex-grow-1 justify-content-center">

            <b-dropdown text="Actions" class="mr-3">
              <b-dropdown-item-button button-class="btn" @click="scheduleSR()"
                                      v-if="order.orderStatus === 'OPEN' || order.orderStatus === 'SCHEDULED'">
                <font-awesome-icon icon="truck"></font-awesome-icon>
                Shiprocket
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn" @click="acceptOrder('SCHEDULED')"
                                      v-if="order.orderStatus === 'OPEN'">
                <font-awesome-icon icon="truck-loading"></font-awesome-icon>
                Schedule
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn" @click="acceptOrder('OPEN')"
                                      v-if="order.orderStatus === 'SCHEDULED'">
                <font-awesome-icon :icon="['fas', 'undo']"></font-awesome-icon>
                Re-Open
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn" @click="acceptOrder('SCHEDULED')"
                                      v-if="order.orderStatus === 'WAITING_FOR_PICKUP'">
                <font-awesome-icon :icon="['fas', 'undo']"></font-awesome-icon>
                Re-Ship
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn" @click="acceptOrder('OPEN')"
                                      v-if="order.orderStatus === 'DRAFT'">
                <font-awesome-icon icon="check"></font-awesome-icon>
                Accept
              </b-dropdown-item-button>

              <b-dropdown-item-button button-class="btn"
                                      v-if="canEdit"
                                      :disabled="order.paymentStatus === 'PAID'"
                                      @click.prevent="editOrder()">
                <font-awesome-icon icon="edit"></font-awesome-icon>
                Edit
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn"
                                      v-if="canCancel"
                                      @click.prevent="discardOrder()">
                <font-awesome-icon icon="times-circle"></font-awesome-icon>
                Cancel
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn"
                                      v-if="canClose"
                                      @click.prevent="closeOrder()">
                <font-awesome-icon icon="check-circle"></font-awesome-icon>
                Delivered
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn" @click.prevent="printOrder()">
                <font-awesome-icon icon="print-search"></font-awesome-icon>
                Print
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn"
                                      v-if="order.modeOfShipment === 'SHIPROCKET'"
                                      @click.prevent="printLabel()">
                <font-awesome-icon icon="print-search"></font-awesome-icon>
                Print Label
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn"
                                      v-if="canShip"
                                      @click.prevent="shipOrder()">
                <font-awesome-icon icon="truck-loading"></font-awesome-icon>
                Shipped
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn"
                                      v-if="canShip"
                                      @click.prevent="amazonLabel()">
                <font-awesome-icon icon="truck-ramp"></font-awesome-icon>
                Amazon Upload
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn"
                                      v-if="order.orderType !== 'STORE'"
                                      @click.prevent="sendMessage()">
                <font-awesome-icon icon="envelope"></font-awesome-icon>
                Message
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn"
                                      v-if="order.orderStatus !== 'CANCELLED' && order.orderStatus !== 'OPEN' && order.orderType !== 'STORE'"
                                      :disabled="order.paymentStatus === 'PAID'"
                                      @click.prevent="sendPaymentLink()">
                <font-awesome-icon icon="comments-dollar"></font-awesome-icon>
                Payment Link
              </b-dropdown-item-button>
              <b-dropdown-item-button button-class="btn"
                                      v-if="order.orderType !== 'STORE'"
                                      :disabled="order.orderStatus !== 'DELIVERED'"
                                      @click.prevent="repeatOrder()">
                <font-awesome-icon icon="sync"></font-awesome-icon>
                Repeat Order
              </b-dropdown-item-button>
            </b-dropdown>

            <make-payment :order="order" v-on:payment-done="paymentDone"></make-payment>
          </div>

          <div class="ml-5" v-if="select">
            <b-form-checkbox v-model="order.selectedForDelivery"
                             size="lg"
                             switch
                             name="order"
                             class="mr-2"
                             :value="order.id"
                             unchecked-value=""></b-form-checkbox>
          </div>
        </div>
      </div>
    </div>

    <b-modal :id="confModal" :title="confTitle" @ok="reallyDoAction" :ok-disabled="!validTr">
      <div class="list-group list-group-flush">
        <div class="list-group-item d-flex justify-content-between flex-column flex-md-row">
          <strong>Customer</strong>
          <span class="mt-2 mt-md-0">{{ order.customer.name }}</span>
        </div>
        <div class="list-group-item d-flex justify-content-between flex-column flex-md-row">
          <strong>Address</strong>
          <span class="mt-2 mt-md-0">{{ order.customerAddress.fullAddress }}</span>
        </div>

        <div
            class="list-group-item d-flex justify-content-between flex-column flex-md-row align-content-center align-items-center">
          <strong>Status</strong>
          <div class="d-flex mt-2 mt-md-0">
            <b-badge variant="primary">{{ order.orderStatus }}</b-badge>
            <span class="ml-3 mr-3">&rarr;</span>
            <b-badge variant="success">{{ orderStatus }}</b-badge>
          </div>
        </div>

        <div class="list-group-item" v-if="isShipped">
          <div class="row">

            <div class="col-12 col-md-3"><strong>Ship Via</strong></div>
            <div class="col-12 col-md-9 mt-2 mt-md-0">
              <b-form-select type="text" v-model="shipVia"
                             @change="updateTrackingLink"
                             :options="modeOfShipments"></b-form-select>
            </div>

          </div>

        </div>
        <div class="list-group-item" v-if="isShipped">
          <div class="row">

            <div class="col-12 col-md-3"><strong>Tracking Number</strong></div>
            <div class="col-12 col-md-9 mt-2 mt-md-0">
              <b-form-input type="text" v-model="tracking" @input="updateTrackingLink"></b-form-input>
            </div>

          </div>

        </div>
        <div class="list-group-item" v-if="isShipped">
          <div class="row">

            <div class="col-12 col-md-3"><strong>Tracking Link</strong></div>
            <div class="col-12 col-md-9 mt-2 mt-md-0">
              <b-form-input type="url" v-model="url"></b-form-input>
            </div>

          </div>

        </div>

      </div>
    </b-modal>
    <form ref="form" :action="amznPrintUrl" method="post" target="_blank">
      <input type="hidden" name="order" :value="order.id">
      <input type="hidden" name="action" value="amazon">
      <input type="hidden" name="x-auth-token" :value="$store.state.authToken">
    </form>
  </div>
</template>
<script>
import PaymentStatus from '@/components/payment-status'
import OrderStatus from '@/components/order-status'
import MakePayment from '@/components/make-payment'
import Rupee from '@/components/rupee'
import axios from 'axios'
import CustomerAvatar from './customer-avatar'
import ShipmentMode from '@/components/shipment-mode'
import DisplayBrand from '@/components/display-brand'
import DontDisturb from '@/components/others/DontDisturb'

export default {
  components: {DontDisturb, DisplayBrand, ShipmentMode, CustomerAvatar, PaymentStatus, MakePayment, Rupee, OrderStatus},
  name: 'OrderDisplay',
  props: ['order', 'hideCustomer', 'hideFooter', 'slno', 'select'],
  computed: {
    amznPrintUrl() {
      return `${process.env.VUE_APP_URL}/api/admin/deliveries/action`
    },
    canEdit () {
      let nonEditable = ['CANCELLED', 'DELIVERED']
      if (!this.order) return false
      return nonEditable.indexOf(this.order.orderStatus) === -1
    },
    canCancel () {
      let cancellable = ['DRAFT', 'OPEN', 'SCHEDULED']
      if (!this.order) return false
      return cancellable.indexOf(this.order.orderStatus) > -1
    },
    canShip () {
      let cancellable = ['OPEN', 'SCHEDULED', 'WAITING_FOR_PICKUP']
      if (!this.order) return false
      return cancellable.indexOf(this.order.orderStatus) > -1
    },
    canClose () {
      if (!this.order) return false
      return this.order.orderStatus !== 'DRAFT' && this.order.orderStatus !== 'CANCELLED' && this.order.orderStatus !== 'DELIVERED'
    },
    orderStatusBadge () {
      if (!this.order) return 'secondary'

      return this.$store.state.orderStatusMap[this.order.orderStatus] || 'secondary'
    },
    customerPage () {
      return {name: 'customer-detail', params: {id: this.order.customer.id}}
    },
    confTitle () {
      return `Update #${this.order.orderNum} to ${this.orderStatus}`
    },
    confModal () {
      return `confModal:${this.order.id}`
    },
    isShipped () {
      return this.orderStatus === 'SHIPPED'
    },
    validTr () {
      let notracking = ['WEFAST', 'SELF']
      if (this.isShipped && notracking.indexOf(this.shipVia) === -1) {
        return this.url.length > 5 && this.tracking.length > 5
      } else {
        return true
      }
    }
  },
  methods: {
    async updateTrackingLink() {
      if(this.shipVia && this.tracking){
        if(this.shipVia === "AMAZON"){
          this.url = `https://track.amazon.in/tracking/${this.tracking}`
        }
        if(this.shipVia === "DELHIVERY"){
          this.url = `https://www.delhivery.com/track-v2/package/${this.tracking}`
        }
      }
    },
    async scheduleSR () {
      await this.$store.dispatch('orderShiprocket', {id: this.order.id})
    },
    async paymentDone () {
      await this.$store.dispatch('orderUpdated', {id: this.order.id, orderNum: this.order.orderNum, status: 'Paid'})
    },
    async editOrder () {
      await this.$store.dispatch('editOrder', {id: this.order.id})
    },
    async discardOrder () {
      this.orderStatus = 'CANCELLED'
      this.$bvModal.show(this.confModal)
    },
    async closeOrder () {
      //ask for confirmation
      this.orderStatus = 'DELIVERED'
      this.$bvModal.show(this.confModal)
    },
    async repeatOrder () {
      let o = (await axios.put(`/api/store/orders/repeat/${this.order.id}`)).data
      this.$bvToast.toast(`New Draft Order for ${o.customer.name} is Created`, {
        title: 'New Order',
        autoHideDelay: 5000,
        appendToast: true,
        variant: 'primary'
      })
      await this.$store.dispatch('orderUpdated', {
        id: this.order.id,
        orderNum: this.order.orderNum,
        status: 'Duplicate'
      })
    },
    async printOrder () {
      await window.open(`${process.env.VUE_APP_URL}/api/orders/print/${this.order.id}?x-auth-token=${this.$store.state.authToken}`)
    },
    async printLabel () {
      await window.open(`${process.env.VUE_APP_URL}/public/shiprocket-label/${this.order.id}`)
    },
    async sendMessage () {
      await this.$store.dispatch('sendMessage', {customer: this.order.customer, brand: this.order.brand})
    },
    async sendPaymentLink () {
      await this.$store.dispatch('sendPaymentLink', this.order)
    },
    async acceptOrder (status) {

      this.orderStatus = status
      this.$bvModal.show(this.confModal)

    },
    async shipOrder () {
      this.orderStatus = 'SHIPPED'
      this.shipVia = this.order.modeOfShipment
      this.$bvModal.show(this.confModal)
    },
    async amazonLabel() {
      this.shipVia = "AMAZON"
      this.order.modeOfShipment = 'AMAZON'
      this.$refs['form'].submit()
    },
    async reallyDoAction () {
      if (this.orderStatus === 'CANCELLED') {
        await axios.delete(`/api/store/orders/${this.order.id}`)
        await this.$store.dispatch('orderUpdated', {
          id: this.order.id,
          orderNum: this.order.orderNum,
          status: 'Cancelled'
        })
      } else {
        await axios.put(`/api/store/orders/${this.order.id}?status=${this.orderStatus}&tracking=${this.tracking}&url=${this.url}&shipVia=${this.shipVia}`)
        await this.$store.dispatch('orderUpdated', {
          id: this.order.id,
          status: this.orderStatus,
          orderNum: this.order.orderNum
        })
      }
      this.$bvModal.hide(this.confModal)
    },
    async editNotes(what){
      if(what === 'admin_note'){
        this.adminNote = this.order.adminNote
        this.editAdminNote = true
      } else {
        this.customerNote = this.order.orderNote
        this.editCustomerNote = true
      }
    },
    async saveNote(what) {
      let payload = what === 'admin_note'
          ? {
            admin_note: this.adminNote
          }
          : {
            order_note: this.customerNote
          }
      await axios.patch(`/api/store/orders/${this.order.id}`, payload)
      this.editAdminNote = false
      this.editCustomerNote = false
      await this.$store.dispatch('orderUpdated', {
        id: this.order.id,
        status: this.orderStatus,
        orderNum: this.order.orderNum
      })
    }
  },
  watch: {},
  data: () => ({
    editAdminNote: false,
    editCustomerNote: false,
    orderStatus: '',
    tracking: '',
    url: '',
    shipVia: '',
    adminNote: '',
    customerNote: '',
    modeOfShipments: ['WEFAST', 'SHIPROCKET', 'SELF', 'AMAZON', 'DELHIVERY'],
  })
}
</script>
<style scoped>
@media (min-width: 576px) {
  .order-display {
    min-width: 20em;
  }
}

@media (max-width: 576px) {
  .m-w-100 {
    width: 100%;
  }
}

.order-display {
  border-radius: 20px;
}

.card-header {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
  border: 0 none;
}

.card-footer {
  border: 0 none;
  border-bottom-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

.list-group-item {
  padding: .5rem;
}

.subs {
  border: 1px solid green;
  box-shadow: 1px 2px 3px green;
}

.name {
  font-weight: bold;
}

.address {
  font-size: x-small;
  max-width: 220px;
  word-break: break-all;
}
</style>
