<template>
  <section class="d-flex flex-column justify-content-center align-items-center align-content-center">
    <h2 class="text-center mb-3">Dashboard</h2>

    <div class="d-flex flex-column flex-md-row justify-content-center align-content-center align-items-center">
      <b-button-group>
        <button class="btn"
                v-for="b of $store.state.brands" :key="b.id"
                @click="toggleBrandSelect(b)"
                v-bind:class="{'btn-outline-success': brands.indexOf(b.id) > -1, 'btn-outline-danger': brands.indexOf(b.id) === -1}">


        <display-brand :border="false" :id="b.id" :name="false"></display-brand>
          <div v-if="brands.indexOf(b.id) > -1">
            <font-awesome-icon icon="check-circle"></font-awesome-icon>
          </div>
          <div v-else>
            <font-awesome-icon icon="times-circle"></font-awesome-icon>
          </div>
        </button>
      </b-button-group>
    </div>

    <hr class="w-100 my-3 py-3">

    <div class="row row-cols-1 row-cols-lg-2 justify-content-center w-100 p-md-5">

      <div class="col p-md-3">
        <div
            class="d-flex flex-column ml-md-5 justify-content-between align-items-center align-content-center mr-md-5 mb-3 mb-md-0">
          <h4 class="mb-3 text-center">Current Orders</h4>
          <table class="table table-sm">
            <tbody>
            <tr>
              <td>
                <b-link :to="{'name': 'admin-orders-type', 'params': {'type': 'OPEN'}}">Open Orders</b-link>
              </td>
              <td>
                <rupee>{{ st.open }}</rupee>
              </td>
            </tr>
            <tr>
              <td>
                <b-link :to="{'name': 'admin-orders-type', 'params': {'type': 'SCHEDULED'}}">Scheduled Orders</b-link>
              </td>
              <td>
                <rupee>{{ st.scheduled }}</rupee>
              </td>
            </tr>
            <tr>
              <td>
                <b-link :to="{'name': 'admin-orders-type', 'params': {'type': 'WAITING_FOR_PICKUP'}}">Waiting for Pickup
                </b-link>
              </td>
              <td>
                <rupee>{{ st.waiting }}</rupee>
              </td>
            </tr>
            <tr>
              <td>
                <b-link :to="{'name': 'admin-orders-type', 'params': {'type': 'SHIPPED'}}">Pickedup</b-link>
              </td>
              <td>
                <rupee>{{ st.picked }}</rupee>
              </td>
            </tr>
            <tr>
              <td>
                <b-link :to="{'name': 'admin-orders-type', 'params': {'type': 'DRAFT'}}">Draft Orders</b-link>
              </td>
              <td>
                <rupee>{{ st.draft }}</rupee>
              </td>
            </tr>
            <tr>
              <td>
                <b-link :to="{'name': 'admin-orders-type', 'params': {'type': 'UNPAID'}}">Payment Pending</b-link>
              </td>
              <td>
                <rupee>{{ st.up }}</rupee>
              </td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="col p-md-3">
        <div
            class="d-flex flex-column ml-md-5 justify-content-between align-items-center align-content-center mr-md-5 mb-3 mb-md-0">
          <h4 class="mb-3 text-center">Special Orders</h4>
          <table class="table">
            <thead class="table-info">
            <tr>
              <th>Product</th>
              <th>Qty</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(b, idx) of brdQty" :key="idx">
              <td>{{ b.first }}</td>
              <td>{{ b.second }}</td>
            </tr>
            </tbody>
          </table>

        </div>
      </div>
      <div class="col p-md-3">
        <div
            class="d-flex flex-column ml-md-5 justify-content-between align-items-center align-content-center mr-md-5 mb-3 mb-md-0">
          <h4 class="mb-3 text-center">Weekly Sales</h4>
          <div :style="chartWidth">
            <bar-chart :chart-data="weekLib" :options="options"></bar-chart>
          </div>
          <table class="mt-3 table table-sm table-borderless table-striped">
            <thead class="table-info">
            <tr>
              <th class="text-center">Week</th>
              <th class="text-center">Sales</th>
              <th class="text-center"># Orders</th>
              <th class="text-center">Avg</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="w in weekly" :key="w.date">
              <td class="text-center">{{ w.date }}</td>
              <td class="text-center">
                <rupee>{{ inr(w.sum) }}</rupee>
              </td>
              <td class="text-center">
                {{ w.count }}
              </td>
              <td class="text-center">
                &#8377; {{ (w.sum / w.count).toFixed(0) }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <div class="col p-md-3">
        <div
            class="d-flex flex-column ml-md-5 justify-content-between align-items-center align-content-center mr-md-5 mb-3 mb-md-0">
          <h4 class="mb-3 text-center">Monthly Sales</h4>
          <div :style="chartWidth">
            <bar-chart :chart-data="monthLib" :options="options"></bar-chart>
          </div>
          <table class="mt-3 table table-sm table-borderless table-striped">
            <thead class="table-info">
            <tr>
              <th class="text-center">Month</th>
              <th class="text-center">Sales</th>
              <th class="text-center"># Orders</th>
              <th class="text-center">Avg</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="m in monthly" :key="m.date">
              <td class="text-center">{{ m.date }}</td>
              <td class="text-center">
                <rupee>{{ inr(m.sum) }}</rupee>
              </td>
              <td class="text-center">
                {{ m.count }}
              </td>
              <td class="text-center">
                &#8377; {{ (m.sum / m.count).toFixed(0) }}
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="col p-md-3 d-flex flex-column justify-content-center align-content-center align-items-center">
        <h4 class="my-3 text-center">Category Wise</h4>
        <table class="mt-3 table table-borderless table-striped">
          <thead class="table-info">
          <tr>
            <th>Category</th>
            <th>Amount</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(b, idx) of catMap" :key="idx">
            <td>
              <span style="text-transform: capitalize">{{ b.name.toLowerCase() }}</span>
            </td>
            <td>
              <rupee>{{ inr(b.qty) }}</rupee>
            </td>
          </tr>
          </tbody>

          <tfoot class="table-info">
          <tr>
            <td>Total</td>
            <td>
              <rupee>
                {{ inr(totalOf) }}
              </rupee>
            </td>
          </tr>
          </tfoot>
        </table>
      </div>
    </div>
  </section>
</template>
<script>
import axios from 'axios'
import { format } from 'date-fns'
import _ from 'lodash'
import Rupee from '@/components/rupee'
import BarChart from '@/chart/barchart'
import ColorHash from 'color-hash'
import DisplayBrand from '@/components/display-brand'

export default {
  name: 'Dashboard',
  components: {DisplayBrand, Rupee, BarChart},
  data () {
    return {
      totalOf: 0,
      brands: [],
      catMap: [],
      options: {
        scales: {
          yAxes: [{
            id: 'A',
            type: 'linear',
            display: false,
            position: 'left',
          }, {
            id: 'B',
            type: 'linear',
            display: false,
            position: 'right',
          }, {
            id: 'C',
            type: 'linear',
            display: false,
            position: 'right',
          }]
        },
      },
      weekly: [],
      monthly: [],
      pending: {},
      st: {
        open: 0,
        scheduled: 0,
        draft: 0,
        picked: 0,
        waiting: 0,
        up: 0,
      },
      brdQty: [],
      weekLib: {},
      monthLib: {},
    }
  },
  methods: {
    async toggleBrandSelect (b) {
      let brandIdx = this.brands.indexOf(b.id)
      if (brandIdx === -1) {
        this.brands.push(b.id)
      } else {
        this.brands.splice(brandIdx, 1)
      }
      await this.getData()
    },
    inr (n) {
      return new Intl.NumberFormat('en-IN').format(n)
    },
    async getData () {
      setTimeout(async () => {
        await this.get()
      }, 200)
    },
    async get () {
      let d = await axios.get('/api/admin/dashboard?brands=' + this.brands.join(','))

      this.st.open = d.data.open
      this.st.scheduled = d.data.scheduled
      this.st.draft = d.data.draft
      this.st.up = d.data.unpaid
      this.st.picked = d.data.picked
      this.st.waiting = d.data.waiting
      this.brdQty = d.data.brdQty
      this.catMap = d.data.catMap
      this.totalOf = d.data.catMap.map(c => c.qty).reduce((a, b) => a + b)

      let weeklySum = []
      let weeklyCount = []
      let weeklyAvg = []
      let monthlySum = []
      let monthlyCount = []
      let monthlyAvg = []

      let colorHash = new ColorHash()
      let weekLib = {
        labels: [],
        datasets: [],
      }

      let monthLib = {
        labels: [],
        datasets: [],

      }
      this.weekly = d.data.weekly.map(w => {
        let w0 = Object.assign({}, w)
        w0.date = format(new Date(w.date), 'd/MMM')
        return w0
      })

      this.monthly = d.data.monthly.map(w => {
        let w0 = Object.assign({}, w)
        w0.date = format(new Date(w.date), 'MMM/yy')
        return w0
      })

      _.reverse(
          d.data.weekly.map(w => {
            return [format(new Date(w.date), 'd/MMM'), w.sum, w.count, Math.floor(w.sum / w.count)]
          })
      ).forEach(m => {
        weekLib.labels.push(m[0])
        weeklySum.push(m[1])
        weeklyCount.push(m[2])
        weeklyAvg.push(m[3])
      })

      weekLib.datasets.push({
        label: 'Order Value', data: weeklySum, yAxisID: 'A', fill: false,
        backgroundColor: colorHash.hex('Order Value')
      })
      weekLib.datasets.push({
        label: 'Order Count', data: weeklyCount, yAxisID: 'B', fill: false,
        backgroundColor: colorHash.hex('Order Count')
      })
      weekLib.datasets.push({
        label: 'Avg Value', data: weeklyAvg, yAxisID: 'C', fill: false,
        backgroundColor: colorHash.hex('Avg Value')
      })

      _.reverse(
          d.data.monthly.map(w => {
            return [format(new Date(w.date), 'MMM/yy'), w.sum, w.count, Math.floor(w.sum / w.count)]
          })
      ).forEach(m => {
        monthLib.labels.push(m[0])
        monthlySum.push(m[1])
        monthlyCount.push(m[2])
        monthlyAvg.push(m[3])
      })

      monthLib.datasets.push({
        label: 'Order Value', data: monthlySum, yAxisID: 'A', fill: false,
        backgroundColor: colorHash.hex('Order Value')
      })
      monthLib.datasets.push({
        label: 'Order Count', data: monthlyCount, yAxisID: 'B', fill: false,
        backgroundColor: colorHash.hex('Order Count')
      })
      monthLib.datasets.push({
        label: 'Avg Value', data: monthlyAvg, yAxisID: 'C', fill: false,
        backgroundColor: colorHash.hex('Avg Value')
      })
      console.log(weekLib)
      console.log(monthLib)

      this.weekLib = weekLib
      this.monthLib = monthLib

      if (this.brands.length === 0) {
        this.brands = this.$store.state.brands.map(b => b.id)
      }
    }
  },
  computed: {
    isMobile () {
      if (window.matchMedia('(min-width: 600px)').matches) {
        /* the viewport is at least 400 pixels wide */
        return false
      } else {
        /* the viewport is less than 400 pixels wide */
        return true
      }
    },
    chartWidth () {
      console.log('window width', window.innerWidth)
      let w = window.innerWidth > 575
          ? '33vw'
          : `${window.innerWidth * 0.8}px`
      return `width:${w}`
    }
  },
  async created () {
    await this.$store.dispatch('loadBrands')
    this.brands = this.$store.state.brands.map(b => b.id)
    await this.get()
  }
}
</script>
<style scoped>
.search-results {
  margin-top: 10px;
}
</style>