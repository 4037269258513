<template>
  <b-modal id="product-recipe" ok-only @ok="saveRecipe"
           @hidden="resetRecipe" :title="title">
    <div class="list-group">
      <div class="list-group-item">
        <b-row>
          <b-col class="col">Ingredient</b-col>
          <b-col class="d-flex justify-content-between">
            Weight (per KG in gms)
            <b-button @click.prevent="recipe.recipe.push({})">+</b-button>
          </b-col>
        </b-row>
      </div>
      <div class="list-group-item" v-for="(rr, idx) of recipe.recipe" :key="idx">
        <b-row>
          <b-col class="col">
            <input type="text" list="ingredients-list" v-model="rr.name" class="form-control" placeholder="Ingredient"/>
          </b-col>
          <b-col class="col">
            <input type="number" v-model="rr.qtyPerKG" class="form-control" step="any"
                   placeholder="Weight in gms/KG"/>
          </b-col>
        </b-row>
      </div>
    </div>
    <div class="list-group">
      <div class="list-group-item">
        <b-row>
          <b-col class="col">Nutrition (per 100g)</b-col>
          <b-col class="col">Value</b-col>
        </b-row>
      </div>
      <div class="list-group-item" v-for="(rr, idx) of recipe.nutritionalValue" :key="idx">
        <b-row>
          <b-col class="col">
            <input type="text" v-model="rr.name" class="form-control" placeholder="Ingredient"/>
          </b-col>
          <b-col class="col">
            <input type="number" v-model="rr.qtyPerKG" class="form-control" step="any" placeholder="Value"/>
          </b-col>
        </b-row>
      </div>
    </div>
    <datalist id="ingredients-list">
      <option v-for="(i, idx) of $store.state.ingredients" :key="idx" :value="i"></option>
    </datalist>

  </b-modal>

</template>

<script>
import {AddProduct} from "@/util";
import axios from "axios";

export default {
  name: 'ProductRecipe',
  data: () => ({
    recipe: {
      recipe: [],
      nutritionalValue: [
          {name: "Energy (in Kcal)", qtyPerKG: 0},
          {name: "Protein (in g)", qtyPerKG: 0},
          {name: "Carbohydrates (in g)", qtyPerKG: 0},
          {name: "Fiber (in g)", qtyPerKG: 0},
          {name: "Fat (in g)", qtyPerKG: 0},
          {name: "Cholesterol (in mg)", qtyPerKG: 0},
      ]
    },
    product: AddProduct()
  }),
  methods: {

    async editRecipe(p) {
      console.log('edit recipe', p)
      this.product = p
      this.recipe = (await axios.get("/api/admin/recipe?id=" + this.product.id)).data
      this.$bvModal.show(`product-recipe`)
    },
    async saveRecipe() {
      await axios.post("/api/admin/recipe?id=" + this.product.id, this.recipe)
      await this.$store.dispatch('loadIngredients')
      await this.resetRecipe()
    },
    async resetRecipe() {
      console.log('reset recipe edit')
      await this.$store.dispatch('setEditProductRecipe', {id: null})
    }
  },
  watch: {
    async editProductRecipe(productId) {

      if (productId) {
        let product = this.$store.state.products.find(p => p.id === productId)
        if (product) {
          await this.editRecipe(product)
        }
      }
    }
  },
  computed: {
    title() {
      return `Edit Recipe for ${this.product.name}`
    },
    editProductRecipe() {
      return this.$store.state.editProductRecipe
    }
  },
}
</script>