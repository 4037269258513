<template id="admin-products">
  <section>
    <div class="d-flex flex-column flex-md-row justify-content-between align-items-md-center">
      <h4>Products</h4>
      <div class="d-flex justify-content-md-end flex-column flex-md-row">
        <div class="mb-2 ml-md-2">
          <b-form-select v-model="cat" @change="filterUpdated">
            <b-form-select-option :value="null">Show All</b-form-select-option>
            <b-form-select-option v-for="c of $store.state.categories" :value="c.id" :key="c.id">{{ c.name }}
            </b-form-select-option>
          </b-form-select>
        </div>
        <div class="mb-2 ml-md-2">
          <b-form-input type="text" placeholder="Search" v-model="filter" debounce="500"/>
        </div>
        <div class="mb-2 ml-md-2">
          <b-form-select v-model="imgFilter" @change="filterUpdated">
            <option value="ALL">With & Without Pictures</option>
            <option value="ONLY">Has Pictures</option>
            <option value="MISSING">Without Pictures</option>
          </b-form-select>
        </div>
        <div class="mb-2 ml-md-2">
          <b-form-select v-model="videoFilter" @change="filterUpdated">
            <option value="ALL">With & Without Videos</option>
            <option value="ONLY">Has Videos</option>
            <option value="MISSING">Without Videos</option>
          </b-form-select>
        </div>
        <div class="mb-2 ml-md-2">
          <b-form-select v-model="productOrder" @change="filterUpdated">
            <option value="NAME">Order By Name</option>
            <option value="SEQ">Order by Seq</option>
          </b-form-select>
        </div>
        <div class="mb-2 ml-md-2">
          <new-product :product="product" :edit="false">
            <font-awesome-icon :icon="['far','plus']"></font-awesome-icon>
            New Product
          </new-product>
        </div>
      </div>
    </div>

    <div class="d-flex flex-wrap justify-content-center justify-content-md-start">
      <admin-product-view
          v-for="p of filteredProducts"
          :key="p.id"
          :product="p"
      ></admin-product-view>
    </div>
  </section>
</template>
<script>
import NewProduct from '@/components/new-product'
import AdminProductView from '@/components/admin-product-view'
import _ from 'lodash'

export default {
  name: 'AdminProducts',
  components: {AdminProductView, NewProduct},
  data: () => ({
    filter: '',
    edit: false,
    imgFilter: 'ALL',
    videoFilter: 'ALL',
    productOrder: 'NAME',
    cat: null,

    product: {
      category: {id: null},
      taxPct: {id: null},
      moq: 1,
      mainImage: '',
      variants: {id: null},
      subTitle: '',
      ingredients: [],
      images: []
    },
    recipe: {
      recipe: []
    },
    activeVariants: [],
    selectedVariants: []
  }),
  async created() {
    await this.$store.dispatch('loadProdCat')
    await this.$store.dispatch('loadProductTags')
    await this.$store.dispatch('loadIngredients')
    let search = window.location.search
    if (search.length > 0) {
      let q = new URLSearchParams(search.substring(1))
      if (q.has("category")) {
        this.cat = q.get("category")
      }
      if (q.has("imgFilter")) {
        this.imgFilter = q.get("imgFilter")
      }
      if (q.has("videoFilter")) {
        this.videoFilter = q.get("videoFilter")
      }
      if (q.has("productOrder")) {
        this.productOrder = q.get("productOrder")
      }
    }
  },
  computed: {
    singleVariantProduct: function () {
      return this.p.variants.length === 1
    },
    filteredProducts: function () {
      let f = this.filter.toLowerCase()
      let f1 = []

      let allProducts = this.allProducts
      if (f === '') f1 = allProducts
      else f1 = allProducts.filter(p => p.name.toLowerCase().indexOf(f) > -1)

      let f2 = f1
      if (this.imgFilter === 'MISSING') {
        f2 = f1.filter(p => p.images.length === 0)
      }
      if (this.imgFilter === 'ONLY') {
        f2 = f1.filter(p => p.images.length > 0)
      }
      if (this.videoFilter === 'MISSING') {
        f2 = f1.filter(p => p.videos.length === 0)
      }
      if (this.videoFilter === 'ONLY') {
        f2 = f1.filter(p => p.videos.length > 0)
      }
      if (this.cat !== null) f2 = f2.filter(p => p.category.id === this.cat)
      let ordFn = this.productOrder === 'NAME' ? a => a.name : a => a.ordering
      return _.take(_.sortBy(f2, ordFn), 20)
    },
    allProducts() {
      return this.$store.state.products
    },
    currUrl() {
      return `${window.location.protocol}//${window.location.hostname}${window.location.pathname}`
    }
  },
  methods: {
    filterUpdated() {
      const url = new URL(this.currUrl);
      url.searchParams.set("imgFilter", this.imgFilter)
      url.searchParams.set("videoFilter", this.videoFilter)
      url.searchParams.set("productOrder", this.productOrder)
      if (this.cat) {
        url.searchParams.set("category", this.cat)
      }
      history.pushState({}, "", url);
    }
  }
}
</script>
<style>
</style>